import React from "react";
import "./pageCloser.scss";

export default function PageCloser() {
  return (
    <div
      className="pageCloser"
      style={{
        backgroundImage: `url('https://res.cloudinary.com/sullivana10/image/upload/v1661897636/spacejoy-XpbtQfr9Skg-unsplash_kvleyk.jpg')`,
      }}
    >
      <div className="callLayout">
        <div className="callContent">
          <h3 className="callText">call today for a free estimate</h3>
          <div className="btnContainer">
            <a href="tel:4149092024" target="_self" className="btn">
              call now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
