import React from "react";
import "./menu.scss";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  color: "#ffffff",
  // borderBottom: "1px solid #808080",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreRoundedIcon sx={{ fontSize: "1.67rem", color: "gray" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#116071;",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "11.5px 17px 11.5px 45px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#252525;",
  color: "#ffffff",
  textTransform: "uppercase",
}));

export default function Menu({ menuOpen, setMenuOpen }) {
  const [expanded, setExpanded] = React.useState("panel");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={"menu " + (menuOpen && "active")}>
      <div className="head">
        <div className="logoContainer">
          <a href="/" className="logoLink">
            <img src="/assets/whiteLogo.png" alt="logoWhite" className="logo" />
          </a>
        </div>
        <div className="IconContainer">
          <div className="closeIcon" onClick={() => setMenuOpen(!menuOpen)}>
            <WestRoundedIcon sx={{ fontSize: "32px", color: "white" }} />
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography
                sx={{ fontFamily: "Palanquin", textTransform: "uppercase" }}
              >
                fireplace services
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/inspection"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  inspection
                </Link>
              </Typography>
            </AccordionDetails>
            {/* <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/repair"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  repair
                </Link>
              </Typography>
            </AccordionDetails> */}
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/remodel"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  remodel
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/wood-inserts"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  wood inserts
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/gas-inserts"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  gas inserts
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/gas-log-install"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  gas log install
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/gas-fireplace-service"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  gas fireplace service
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/new-construction"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  new construction
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/custom-doors"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  custom doors
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services/fireplace-reline"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  fireplace reline
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/fireplace-services"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  view all
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography
                sx={{ fontFamily: "Palanquin", textTransform: "uppercase" }}
              >
                chimney services
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/sweep-and-inspection"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  sweep & inspection
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/chimney-rebuild"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  chimney rebuild
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/tuck-pointing"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  tuck-pointing
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/concrete-crowns"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  concrete crowns
                </Link>
              </Typography>
            </AccordionDetails>
            {/* <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/chimney-caps"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  chimney caps
                </Link>
              </Typography>
            </AccordionDetails> */}
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/chimney-liners"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  chimney liners
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/flashing"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  flashing
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/crown-coat"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  crown coat
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/chase-covers"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  chase covers
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/power-washing"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  power washing
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services/pcr"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  P.C.R. Creosote Glaze Removal
                </Link>
              </Typography>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
                <Link
                  to="/chimney-services"
                  className="accLink"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  view all
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <div className="galleryContainer">
            <Link className="galleryMenuLink" to="/gallery" onClick={() => setMenuOpen(!menuOpen)}>
              project gallery
            </Link>
          </div> */}
          <div className="contactContainer">
            <Link
              className="contact"
              to="/contact"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              contact
            </Link>
          </div>
        </div>

        <div className="socials">
          <div className="icon">
            <InstagramIcon
              onClick={(event) =>
                window.open(
                  "https://www.instagram.com/midwestfireplaceandchimney",
                  "_blank"
                )
              }
            />
          </div>
          <div className="icon">
            <FacebookIcon
              onClick={(event) =>
                window.open(
                  "https://www.facebook.com/midwestfireplaceandchimney",
                  "_blank"
                )
              }
            />
          </div>
          <div className="icon">
            <TwitterIcon
              onClick={(event) =>
                window.open("https://www.twitter.com/MFC_Milwaukee", "_blank")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
