import React from "react";
import "./contactPage.scss";
import Contact from "../../components/contact/Contact";
import Map from "../../components/map/Map";

export default function contactPage() {
  document.title = "Contact | Midwest Fireplace and Chimney Ltd";
  return (
    <>
      <div
        className="contactHeader"
        style={{
          backgroundImage: `url('../../assets/brick.jpg')`,
        }}
      >
        <h1 className="contact">contact</h1>
      </div>
      <Contact />
      <Map />
    </>
  );
}
