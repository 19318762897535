import React from "react";
import "./contact.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Contact() {
  function loading() {
    const load = document.getElementById("submit");
    load.classList.toggle("loadingClass");
    const visible = document.getElementById("boxContainer");
    visible.classList.toggle("boxVis");
  }
  return (
    <div className="contact">
      <div className="layout">
        <div className="content">
          <div className="infoContainer">
            <div className="infoContent">
              <h3 className="name">midwest fireplace and chimney ltd.</h3>
              <div className="address">
                <strong className="label">Address</strong>
                <br />
                <p className="details">
                  PO Box 20588
                  <br />
                  Milwaukee, WI 53220
                </p>
              </div>
              <p className="phone">
                <strong className="label">Phone</strong>
                <br />
                <a href="tel:4149092024" target="_self" className="details">
                  414-909-2024
                </a>
              </p>
              <p className="hours">
                <strong className="label">Hours of Operation</strong>
                <br />
                Monday: 9:00 AM - 5:00 PM
                <br />
                Tuesday: 9:00 AM - 5:00 PM
                <br />
                Wednesday: 9:00 AM - 5:00 PM
                <br />
                Thursday: 9:00 AM - 5:00 PM
                <br />
                Friday: 9:00 AM - 5:00 PM
              </p>
            </div>
          </div>
          <div className="formContainer">
            <div className="formContent">
              <form
                action="https://formsubmit.co/e48d9231223250251762d9ec3bc5410e"
                method="POST"
                className="form"
                onSubmit={loading}
              >
                <input
                  type="hidden"
                  name="_next"
                  value="https://midwestfireplaceandchimney.com/thank-you"
                ></input>
                <input type="hidden" name="_captcha" value="false"></input>
                <input
                  type="text"
                  name="_honey"
                  style={{ display: "none" }}
                ></input>
                <div className="row">
                  <label className="left" htmlFor="firstName">
                    First Name *
                    <input
                      type="text"
                      className="firstName"
                      placeholder="First Name"
                      name="First&nbsp;Name"
                      required
                    />
                  </label>
                  <label className="right" htmlFor="lastName">
                    Last Name *
                    <input
                      type="text"
                      className="lastName"
                      placeholder="Last Name"
                      name="Last&nbsp;Name"
                      required
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="left" htmlFor="phone">
                    Phone
                    <input
                      type="tel"
                      className="phone"
                      placeholder="Phone"
                      name="Phone"
                    />
                  </label>
                  <label className="right" htmlFor="email">
                    Email *
                    <input
                      type="email"
                      className="email"
                      placeholder="Email"
                      name="Email"
                      required
                    />
                  </label>
                </div>
                <div className="row">
                  <label className="bottom" htmlFor="message">
                    <span>Message</span>
                    <textarea
                      name="message"
                      placeholder="Write your message"
                      className="message"
                      required
                    ></textarea>
                  </label>
                </div>
                <div className="btnContainer">
                  <input
                    className="submitBtn"
                    id="submit"
                    type="submit"
                    value="submit"
                  ></input>
                  <div className="boxContainer" id="boxContainer">
                    <Box
                      className="loading"
                      id="circle"
                      sx={{ display: "flex", color: "white" }}
                    >
                      <CircularProgress color="inherit" />
                    </Box>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
