import React from "react";
import { Link } from "react-router-dom";
import "./footer.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footerTop">
        <div className="container">
          <div className="content">
            <div className="links">
              <Link to="/" className="link">
                Home
              </Link>
              <Link to="/fireplace-services" className="link">
                Fireplace Services
              </Link>
              <Link to="/chimney-services" className="link">
                Chimney Services
              </Link>
              {/* <Link to="/gallery" className="link">
                Gallery
              </Link> */}
              <Link to="/contact" className="link">
                Contact
              </Link>
            </div>
            <div className="logo">
              <span className="badgerText">
                proudly
                <br />
                partnered
                <br />
                with
              </span>
              <div className="badgerLand">
                <img
                  className="badgerLogo"
                  src="/assets/BadgerLandLogo.png"
                  alt="badgerLand"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footerBottom">
        <div className="container">
          <div className="content">
            <div className="textContainer">
              <p className="text">
                po box 20588 milwaukee, wi 53220
                <br />© 2023 midwest fireplace and chimney ltd.
                <br />
                all rights reserved
              </p>
            </div>
            <div className="logoContainer">
              <a href="/" className="logoLink">
                <img
                  src="/assets/whiteLogo.png"
                  alt="footerLogo"
                  className="logo"
                />
              </a>
            </div>
            <div className="icons">
              <div className="iconContainer">
                <div className="icon">
                  <InstagramIcon
                    sx={{ fontSize: "35px" }}
                    onClick={(event) =>
                      window.open(
                        "https://www.instagram.com/midwestfireplaceandchimney",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <div className="iconContainer">
                <div className="icon">
                  <FacebookIcon
                    sx={{ fontSize: "35px" }}
                    onClick={(event) =>
                      window.open(
                        "https://www.facebook.com/midwestfireplaceandchimney",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <div className="iconContainer">
                <div className="icon">
                  <TwitterIcon
                    sx={{ fontSize: "35px" }}
                    onClick={(event) =>
                      window.open(
                        "https://www.twitter.com/MFC_Milwaukee",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footerBottomMbl">
        <div className="container">
          <div className="content">
            <div className="logoContainer">
              <a href="/" className="logoLink">
                <img
                  src="/assets/whiteLogo.png"
                  alt="footerLogo"
                  className="logo"
                />
              </a>
            </div>
            <div className="icons">
              <div className="iconContainer">
                <div className="icon">
                  <InstagramIcon
                    sx={{ fontSize: "35px" }}
                    onClick={(event) =>
                      window.open(
                        "https://www.instagram.com/midwestfireplaceandchimney",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <div className="iconContainer">
                <div className="icon">
                  <FacebookIcon
                    sx={{ fontSize: "35px" }}
                    onClick={(event) =>
                      window.open(
                        "https://www.facebook.com/midwestfireplaceandchimney",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <div className="iconContainer">
                <div className="icon">
                  <TwitterIcon
                    sx={{ fontSize: "35px" }}
                    onClick={(event) =>
                      window.open(
                        "https://www.twitter.com/MFC_Milwaukee",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="textContainer">
              <p className="text">
                po box 20588 milwaukee, wi 53220
                <br />© 2022 midwest fireplace and chimney ltd.
                <br />
                all rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
