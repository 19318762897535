import React from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "transparent",
  transition: "color .2s ease-in",
  zIndex: "10",
  // borderBottom: "1px solid #808080",
  "&:not(:last-child)": {
    // borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&:hover": {
    color: "#116071;",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary sx={{ padding: "0 3px 0 0" }}
    expandIcon={
      <ExpandMoreRoundedIcon sx={{ fontSize: "1.67rem", color: "gray" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "transparent",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "11.5px 0px 11.5px 0px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#252525;",
  color: "#ffffff",
  textTransform: "uppercase",
  display: "flex",
  justifyContent: "center",
  transition: "color .2s ease-in",

  "&:hover": {
    color: "#116071",
  },
}));

export default function Navbar({ menuOpen, setMenuOpen }) {
  const [expanded, setExpanded] = React.useState("panel");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="hamburgerContainer">
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
        <div className="logoContainer">
          <Link to="/" className="logoLink" onClick={handleChange()}>
            <img src="/assets/whiteLogo.png" alt="logoWhite" className="logo" />
          </Link>
        </div>
        <div className="iconContainer">
          <div className="icon">
            <a href="tel:4149092024" target="_self" className="phone">
              <PhoneEnabledIcon
                className="phone"
              />
            </a>
          </div>
        </div>
        <div className="linkContainer">
          <div className="content">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ maxWidth: "300px" }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography
                  sx={{
                    fontFamily: "Palanquin",
                    textTransform: "uppercase",
                    width: "auto",
                  }}
                >
                  fireplace services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/inspection"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    inspection
                  </Link>
                </Typography>
              </AccordionDetails>
              {/* <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/repair"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    repair
                  </Link>
                </Typography>
              </AccordionDetails> */}
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/remodel"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    remodel
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/wood-inserts"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    wood inserts
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/gas-inserts"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    gas inserts
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/gas-log-install"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    gas log install
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/gas-fireplace-service"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    gas fireplace service
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/new-construction"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    new construction
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/custom-doors"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    custom doors
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services/fireplace-reline"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    fireplace reline
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/fireplace-services"
                    className="accLink"
                    onClick={handleChange("panel1")}
                  >
                    view all
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="content">
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography
                  sx={{
                    fontFamily: "Palanquin",
                    textTransform: "uppercase",
                    width: "auto",
                  }}
                >
                  chimney services
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/sweep-and-inspection"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    sweep & inspection
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/chimney-rebuild"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    chimney rebuild
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/tuck-pointing"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    tuck-pointing
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/concrete-crowns"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    concrete crowns
                  </Link>
                </Typography>
              </AccordionDetails>
              {/* <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/chimney-caps"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    chimney caps
                  </Link>
                </Typography>
              </AccordionDetails> */}
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/chimney-liners"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    chimney liners
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/flashing"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    flashing
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/crown-coat"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    crown coat
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/chase-covers"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    chase covers
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/power-washing"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    power washing
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services/pcr"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    P.C.R. Creosote Removal
                  </Link>
                </Typography>
              </AccordionDetails>
              <AccordionDetails>
                <Typography>
                  <Link
                    to="/chimney-services"
                    className="accLink"
                    onClick={handleChange("panel2")}
                  >
                    view all
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          {/* <div className="galleryContainer">
            <Link className="galleryLink" to="/gallery" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })} onClick={handleChange()}>
              gallery
            </Link>
          </div> */}
          <div className="contactContainer">
            <Link className="contact" to="/contact" onClick={handleChange()}>
              contact
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}