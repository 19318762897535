import React from "react";
import "./allServices.scss";
import PageCloser from "../../components/pageCloser/PageCloser";
import { Link } from "react-router-dom";

export default function AllServices() {
  document.title = "All Services | Midwest Fireplace and Chimney Ltd";
  return (
    <div className="allServices">
      <div
        className="allServicesHeader"
        style={{
          backgroundImage: `url('../../assets/brick.jpg')`,
        }}
      >
        <h1 className="title">all services</h1>
      </div>
      <div className="container">
        <div className="layout">
          <h2 className="title">fireplace services</h2>
          <div className="content">
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/inspection">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663908541/MFC_Services/Inspection_Cam_fhz35w.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">inspection</div>
            </div>
            {/* <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/repair">
                  <img
                    src="assets/fpservices.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">repair</div>
            </div> */}
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/remodel">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663909055/MFC_Services/Remodel_Stone-Veneer_lfeyqo.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">remodel</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/wood-inserts">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663909964/MFC_Services/Wood_Insert_Install_e2rjjm.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">wood inserts</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/gas-inserts">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663979186/MFC_Services/Gas_Inserts_g0hsok.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">gas inserts</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/gas-log-install">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663910319/MFC_Services/Gas_Logs_2_p1yvnx.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">gas log install</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/gas-fireplace-service">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663911861/MFC_Services/Gas_Fireplace_Service_stnnkl.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">gas fireplace service</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/new-construction">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663910619/MFC_Services/New_Construction_lio4dx.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">new construction</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/custom-doors">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663911008/MFC_Services/Custom_Doors_2_a9ykd0.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">custom doors</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/fireplace-reline">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663911327/MFC_Services/Fireplace_Reline_maorp8.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">fireplace reline</div>
            </div>
          </div>
          <h2 className="title">chimney services</h2>
          <div className="content">
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/sweep-and-inspection">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663913209/MFC_Services/Cleaning_Inspection_laibr3.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">sweep & inspection</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chimney-rebuild">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903746/MFC_Services/Rebuild_2_sed3tl.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">chimney rebuild</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/tuck-pointing">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903855/MFC_Services/Tuck-Pointing_g4ynz1.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">tuck-pointing</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/concrete-crowns">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663902748/MFC_Services/Chimney_Crown_2_omfmd8.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">concrete crowns</div>
            </div>
            {/* <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chimney-caps">
                  <img src="assets/chimney.jpg" alt="chimney" className="img" />
                </Link>
              </div>
              <div className="text">chimney caps</div>
            </div> */}
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chimney-liners">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663902974/MFC_Services/Chimney_Liners_drkabo.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">chimney liners</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/flashing">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903219/MFC_Services/flashing_ne8mzm.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">flashing</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/crown-coat">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903597/MFC_Services/Crown_Coat_giqltr.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">crown coat</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chase-covers">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663906239/MFC_Services/Chimney_Chase_Cover_After_uprfmt.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">chase covers</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/power-washing">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663907848/MFC_Services/BEFORE_gojjja.png"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">power washing</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/pcr">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1664211560/pcr_vijtrp.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">P.C.R. Creosote Removal</div>
            </div>
          </div>
        </div>
      </div>
      <PageCloser />
    </div>
  );
}
