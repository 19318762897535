import React from "react";
import Home from "../../components/home/Home";
import Services from "../../components/services/Services";
import About from "../../components/about/About";
//import Gall from "../../components/gall/Gall";
import Contact from "../../components/contact/Contact";

export default function HomePage() {
  document.title =
    "Superior Fireplace and Chimney Services | Milwaukee, WI | Midwest Fireplace and Chimney Ltd";
  return (
    <>
      <Home />
      <Services />
      <About />
      {/* <Gall /> */}
      <Contact />
    </>
  );
}
