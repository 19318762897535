import React from "react";
import "./about.scss";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <div
      className="about"
      style={{
        backgroundImage: `url('https://res.cloudinary.com/sullivana10/image/upload/v1661538233/drew-beamer-kUHfMW8awpE-unsplash_slagkj.jpg')`,
      }}
    >
      <div className="layout">
        <div className="content">
          <div className="textContainer">
            <h3 className="title">
              professional fireplace & chimney service in milwaukee, wi
            </h3>
            <p className="text">
              Midwest Fireplace & Chimney seeks to serve the Southeast Wisconsin
              area with superior chimney and fireplace services, ranging from
              annual maintenance, renovations, all the way up to brand new
              installations. Our range of services include: chimney reline,
              chimney sweeps and inspections, appliance liners, caps, crowns,
              tuck pointing, chimney rebuilds, water-proofing, power washing,
              flashings, fireplace gas logs, fireplace gas inserts, wood
              inserts, wood stacks, smoke chamber repair, heat shield repair,
              chase covers, full fireplace replace, chase rebuild, new fireplace
              construction.
            </p>
          </div>
          <div className="btnContainer">
            <Link to="/all-services" className="btn">
              view all services
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
