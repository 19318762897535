import React from "react";
import "./notFound.scss";
import { Link } from "react-router-dom";

export default function NotFound() {
  document.title = "Page Not Found | Midwest Fireplace and Chimney Ltd";
  return (
    <div className="notFound">
      <div
        className="notFoundHeader"
        style={{
          backgroundImage: `url('../../assets/brick.jpg')`,
        }}
      >
        <h1 className="title">page not found</h1>
      </div>
      <div className="layout">
        <div className="container">
          <div className="content">
            <strong className="help">
              It seems we can't find what you're looking for. Perhaps one of the
              links below can help.
            </strong>
            <ul className="menu404">
              <li>
                <Link className="link" to="/fireplace-services">
                  Fireplace Services
                </Link>
              </li>
              <ul className="inside">
                <li>
                  <Link className="link" to="/fireplace-services/inspection">
                    Inspection
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/fireplace-services/remodel">
                    Remodel
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/fireplace-services/wood-inserts">
                    Wood Inserts
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/fireplace-services/gas-inserts">
                    Gas Inserts
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    to="/fireplace-services/gas-log-install"
                  >
                    Gas Log Install
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    to="/fireplace-services/gas-fireplace-service"
                  >
                    Gas Fireplace Service
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    to="/fireplace-services/new-construction"
                  >
                    New Construction
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/fireplace-services/custom-doors">
                    Custom Doors
                  </Link>
                </li>
                <li>
                  <Link
                    className="link"
                    to="/fireplace-services/fireplace-reline"
                  >
                    Fireplace Reline
                  </Link>
                </li>
              </ul>
              <li>
                <Link className="link" to="/chimney-services">
                  Chimney Services
                </Link>
              </li>
              <ul className="inside">
                <li>
                  <Link
                    className="link"
                    to="/chimney-services/sweep-and-inspection"
                  >
                    Sweep & Inspection
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/chimney-rebuild">
                    Chimney Rebuild
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/tuck-pointing">
                    Tuck-Pointing
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/concrete-crowns">
                    Concrete Crowns
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/chimney-liners">
                    Chimney Liners
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/flashing">
                    Flashing
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/crown-coat">
                    Crown Coat
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/chase-covers">
                    Chase Covers
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/power-washing">
                    Power Washing
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/chimney-services/pcr">
                    P.C.R. Creosote Glaze Removal
                  </Link>
                </li>
              </ul>
              {/* <li>
                <Link className="link" to="/gallery">
                  Project Gallery
                </Link>
              </li> */}
              <li>
                <Link className="link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
