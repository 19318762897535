import React from "react";
import "./thankYou.scss";
import PageCloser from "../../components/pageCloser/PageCloser";

export default function ThankYou() {
  document.title = "Thank You | Midwest Fireplace and Chimney Ltd";
  return (
    <div className="thankYou">
      <React.Fragment>
        <div
          className="thankYouHeader"
          style={{
            backgroundImage: `url('../../assets/brick.jpg')`,
          }}
        >
          <h1 className="title">thank you</h1>
        </div>
        <div className="layout">
          <div className="container">
            <div className="content">
              <h5 className="thankYouText">
                thank you for contacting us. we have received your information
                and will get back to you shortly.
              </h5>
            </div>
          </div>
        </div>
      </React.Fragment>
      <PageCloser />
    </div>
  );
}
