import React from "react";
import "./map.scss";

export default function Map() {
  return (
    <div className="map">
      <div className="layout">
        <div className="container">
          <div className="header">
            <div className="subText">midwest fireplace and chimney ltd.</div>
            <div className="title">SERVICE AREA</div>
          </div>
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1UAADPBW1J57Jb2nX-n9tIW5FikoE6QA&ehbc=2E312F"
            title="map"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
