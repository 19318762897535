import React from "react";
import "./cItem.scss";
import PageCloser from "../../components/pageCloser/PageCloser";
import { useParams } from "react-router-dom";
import { cItemData } from "../../data";

export default function FpItem1() {
  const { id } = useParams();
  const pageData = cItemData.find((pageData) => pageData.id === id);

  document.title = `${pageData.header} | Midwest Fireplace and Chimney Ltd`;

  return (
    <div className="cItem">
      <React.Fragment>
        <div
          className="cItemHeader"
          style={{
            backgroundImage: `url('../../assets/brick.jpg')`,
          }}
        >
          <h1 className="title">{pageData.header}</h1>
        </div>
        <div className="whiteSpace"></div>
        {/* <div className="topContainer">
          <div className="topLayout">
            <div className="topContent">
              <h2 className="topTitle">{pageData.p1Title}</h2>
              <p className="topText">{pageData.p1}</p>
              <div className="btnContainer">
                <Link to="/contact" className="btn">
                  contact us
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="midContainer"
          style={{
            backgroundImage: `url('https://res.cloudinary.com/sullivana10/image/upload/v1661538233/drew-beamer-kUHfMW8awpE-unsplash_slagkj.jpg')`,
          }}
        >
          <div className="midLayout">
            <div className="midContent">
              <div className="imgContainer">
                <img
                  src={`${pageData.imgUrl}`}
                  alt="inspection"
                  className="img"
                />
              </div>
              <div className="midTextContent">
                <h3 className="midTitle">{pageData.p2Title}</h3>
                <p className="midText">{pageData.p2}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="whiteSpace"></div>
        {/* <div className="btmContainer">
          <div className="btmLayout">
            <div className="btmContent">
              <h3 className="btmTitle">{pageData.p3Title}</h3>
              <p className="btmText">{pageData.p3}</p>
            </div>
          </div>
        </div> */}
      </React.Fragment>
      <PageCloser />
    </div>
  );
}
