import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import Menu from "./components/menu/Menu";
import Footer from "./components/footer/Footer";
//import Gall from "./components/gall/Gall";
import HomePage from "./pages/homePage/HomePage";
import ContactPage from "./pages/contactPage/ContactPage";
import FireplaceServices from "./pages/fireplaceServices/FireplaceServices";
import ChimneyServices from "./pages/chimneyServices/ChimneyServices";
import FpItem from "./pages/fpItem/FpItem";
import CItem from "./pages/cItem/CItem";
import NotFound from "./pages/notFound/NotFound";
import AllServices from "./pages/allServices/AllServices";
import ScrollToTop from "./scrollToTop";
import ThankYou from "./pages/thankYou/ThankYou";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawer2Open, setDrawer2Open] = useState(false);
  return (
    <div className="App">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        drawer2Open={drawer2Open}
        setDrawer2Open={setDrawer2Open}
      />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* <Route path="/gallery" element={<Gall />} /> */}
          <Route path="/fireplace-services" element={<FireplaceServices />} />
          <Route path="/fireplace-services/:id" element={<FpItem />} />
          <Route path="/chimney-services" element={<ChimneyServices />} />
          <Route path="/chimney-services/:id" element={<CItem />} />
          <Route path="/all-services" element={<AllServices />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
