import React from "react";
import "./services.scss";
// import EngineeringOutlinedIcon from "@mui/icons-material/EngineeringOutlined";
// import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
// import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";

export default function Services() {
  return (
    <div className="services">
      <div className="layout">
        <div className="container">
          {/* <div className="header">
            <div className="subText">about our team</div>
          </div> */}
          <div className="reasons">
            <div className="reasonContainer">
              {/* <div className="iconContainer">
                <div className="icon blue">
                  <div>
                    <EngineeringOutlinedIcon sx={{ fontSize: "50px" }} />
                  </div>
                </div>
              </div> */}
              <div className="label">
                CSIA Certified with Over 20 Years of Experience
              </div>
              <p className="description">
                With over 20 years of experience in the industry our fireplace &
                chimney technicians in the Milwaukee area are the professionals
                that you can rely on and trust. All of our technicians are CSIA
                certified, National Chimney Sweep Guild members, fully insured,
                and passionate about their work. When it comes to your fireplace
                & chimney needs, you can rest assured that we will only provide
                the highest quality of work in a timely manner. Count on our
                experienced professionals to get the job done right the first
                time.
              </p>
            </div>
            {/* <div className="reasonContainer">
              <div className="iconContainer">
                <div className="icon white">
                  <div>
                    <PendingActionsOutlinedIcon sx={{ fontSize: "50px" }} />
                  </div>
                </div>
              </div>
              <div className="label">On-Time Project Completion</div>
              <p className="description">
                Midwest Fireplace and Chimney Ltd. is the fireplace and chimney
                company that you can count on to get the job done in the
                timeframe that has been agreed upon. Efficiency and quality is
                what our fireplace and chimney contractors in the Milwaukee area
                always aim to provide in every project that we accept.
              </p>
            </div>
            <div className="reasonContainer">
              <div className="iconContainer">
                <div className="icon blue">
                  <div>
                    <ThumbUpOutlinedIcon sx={{ fontSize: "50px" }} />
                  </div>
                </div>
              </div>

              <span className="label">Highest Quality</span>
              <p className="description">
                At our fireplace and chimney company, we ensure to provide the
                highest quality of services as well as high-quality materials.
                We will make sure that your fireplace and chimney are in top
                condition and safe for your home and family. Count on our
                fireplace and chimney contractors in the Milwaukee area to
                provide you nothing but the best!
              </p>
            </div> */}
          </div>
          <div className="certification">
            <div className="logoContainer">
              <img src="/assets/csia.png" alt="csia" className="logo" />
            </div>
            <div className="logoContainer">
              <img src="/assets/guild.png" alt="guild" className="logo" />
            </div>
            <div className="logoContainer2">
              <img src="/assets/BBB-Seal3.png" alt="bbb" className="logo2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
