import React from "react";
import "./fireplaceServices.scss";
import PageCloser from "../../components/pageCloser/PageCloser";
import { Link } from "react-router-dom";

export default function FireplaceServices() {
  document.title = "Fireplace Services | Midwest Fireplace and Chimney Ltd";
  return (
    <div className="fireplaceServices">
      <div
        className="fireplaceServicesHeader"
        style={{
          backgroundImage: `url('../../assets/brick.jpg')`,
        }}
      >
        <h1 className="title">fireplace services</h1>
      </div>
      <div className="container">
        <div className="layout">
          <div className="content">
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/inspection">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663908541/MFC_Services/Inspection_Cam_fhz35w.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">inspection</div>
            </div>
            {/* <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/repair">
                  <img
                    src="assets/fpservices.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">repair</div>
            </div> */}
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/remodel">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663909055/MFC_Services/Remodel_Stone-Veneer_lfeyqo.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">remodel</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/wood-inserts">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663909964/MFC_Services/Wood_Insert_Install_e2rjjm.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">wood inserts</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/gas-inserts">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663979186/MFC_Services/Gas_Inserts_g0hsok.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">gas inserts</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/gas-log-install">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663910319/MFC_Services/Gas_Logs_2_p1yvnx.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">gas log install</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/gas-fireplace-service">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663911861/MFC_Services/Gas_Fireplace_Service_stnnkl.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">gas fireplace service</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/new-construction">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663910619/MFC_Services/New_Construction_lio4dx.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">new construction</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/custom-doors">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663911008/MFC_Services/Custom_Doors_2_a9ykd0.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">custom doors</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/fireplace-services/fireplace-reline">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663911327/MFC_Services/Fireplace_Reline_maorp8.jpg"
                    alt="fpservices"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">fireplace reline</div>
            </div>
          </div>
        </div>
      </div>
      <PageCloser />
    </div>
  );
}
