import React from "react";
import "./chimneyServices.scss";
import PageCloser from "../../components/pageCloser/PageCloser";
import { Link } from "react-router-dom";

export default function ChimneyServices() {
  document.title = "Chimney Services | Midwest Fireplace and Chimney Ltd";
  return (
    <div className="chimneyServices">
      <div
        className="chimneyServicesHeader"
        style={{
          backgroundImage: `url('../../assets/brick.jpg')`,
        }}
      >
        <h1 className="title">chimney services</h1>
      </div>
      <div className="container">
        <div className="layout">
          <div className="content">
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/sweep-and-inspection">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663913209/MFC_Services/Cleaning_Inspection_laibr3.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">sweep & inspection</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chimney-rebuild">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903746/MFC_Services/Rebuild_2_sed3tl.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">chimney rebuild</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/tuck-pointing">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903855/MFC_Services/Tuck-Pointing_g4ynz1.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">tuck-pointing</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/concrete-crowns">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663902748/MFC_Services/Chimney_Crown_2_omfmd8.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">concrete crowns</div>
            </div>
            {/* <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chimney-caps">
                  <img src="assets/chimney.jpg" alt="chimney" className="img" />
                </Link>
              </div>
              <div className="text">chimney caps</div>
            </div> */}
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chimney-liners">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663902974/MFC_Services/Chimney_Liners_drkabo.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">chimney liners</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/flashing">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903219/MFC_Services/flashing_ne8mzm.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">flashing</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/crown-coat">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663903597/MFC_Services/Crown_Coat_giqltr.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">crown coat</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/chase-covers">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663906239/MFC_Services/Chimney_Chase_Cover_After_uprfmt.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">chase covers</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/power-washing">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1663907848/MFC_Services/BEFORE_gojjja.png"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">power washing</div>
            </div>
            <div className="service">
              <div className="imgContainer">
                <Link to="/chimney-services/pcr">
                  <img
                    src="https://res.cloudinary.com/sullivana10/image/upload/v1664211560/pcr_vijtrp.jpg"
                    alt="chimney"
                    className="img"
                  />
                </Link>
              </div>
              <div className="text">P.C.R. Creosote Removal</div>
            </div>
          </div>
        </div>
      </div>
      <PageCloser />
    </div>
  );
}
