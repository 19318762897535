import React from "react";
import { Link } from "react-router-dom";
import "./home.scss";

export default function Home() {
  return (
    <div
      className="home"
      style={{
        backgroundImage: `url('assets/homeFinal.jpg')`,
      }}
    >
      <div className="layout">
        <div className="content">
          <div className="text">
            <h1>midwest fireplace and chimney ltd.</h1>
            <br />
            <span className="subText">
              professional fireplace & chimney services
            </span>
          </div>
          <div className="btnContainer">
            <Link to="/contact" className="btn">
              contact us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
