export const gallItemData = [
  {
    id: 1,
    imgSrc: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
    title: 'Bed',
  },
  {
    id: 2,
    imgSrc: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
    title: 'Books',
  },
  {
    id: 3,
    imgSrc: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
    title: 'Sink',
  },
  {
    id: 4,
    imgSrc: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
    title: 'Kitchen',
  },
  {
    id: 5,
    imgSrc: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
    title: 'Blinds',
  },
  {
    id: 6,
    imgSrc: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
    title: 'Chairs',
  },
  {
    id: 7,
    imgSrc: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
    title: 'Laptop',
  },
  {
    id: 8,
    imgSrc: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
    title: 'Doors',
  },
  {
    id: 9,
    imgSrc: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
    title: 'Coffee',
  },
  {
    id: 10,
    imgSrc: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
    title: 'Storage',
  },
  {
    id: 11,
    imgSrc: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
    title: 'Candle',
  },
  {
    id: 12,
    imgSrc: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
    title: 'Coffee table',
  },
  {
    id: 13,
    imgSrc: 'https://images.unsplash.com/photo-1549388604-817d15aa0110',
    title: 'Bed2',
  },
  {
    id: 14,
    imgSrc: 'https://images.unsplash.com/photo-1525097487452-6278ff080c31',
    title: 'Books2',
  },
  {
    id: 15,
    imgSrc: 'https://images.unsplash.com/photo-1523413651479-597eb2da0ad6',
    title: 'Sink2',
  },
  {
    id: 16,
    imgSrc: 'https://images.unsplash.com/photo-1563298723-dcfebaa392e3',
    title: 'Kitchen2',
  },
  {
    id: 17,
    imgSrc: 'https://images.unsplash.com/photo-1588436706487-9d55d73a39e3',
    title: 'Blinds2',
  },
  {
    id: 18,
    imgSrc: 'https://images.unsplash.com/photo-1574180045827-681f8a1a9622',
    title: 'Chairs2',
  },
  {
    id: 19,
    imgSrc: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
    title: 'Laptop2',
  },
  {
    id: 20,
    imgSrc: 'https://images.unsplash.com/photo-1481277542470-605612bd2d61',
    title: 'Doors2',
  },
  {
    id: 21,
    imgSrc: 'https://images.unsplash.com/photo-1517487881594-2787fef5ebf7',
    title: 'Coffee2',
  },
  {
    id: 22,
    imgSrc: 'https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee',
    title: 'Storage2',
  },
  {
    id: 23,
    imgSrc: 'https://images.unsplash.com/photo-1597262975002-c5c3b14bbd62',
    title: 'Candle2',
  },
  {
    id: 24,
    imgSrc: 'https://images.unsplash.com/photo-1519710164239-da123dc03ef4',
    title: 'Coffee table2',
  },
]

export const fpItemData = [
  {
    id: "inspection",
    header: "Fireplace Inspection",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663908541/MFC_Services/Inspection_Cam_fhz35w.jpg",
    p1Title: "fireplace inspections in milwaukee, wi",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "our fireplace inspection process",
    p2: "Our highly trained and experienced technicians specialize in comprehensive fireplace inspections that encompass all essential elements. From examining the chimney structure for cracks, blockages, or signs of wear to inspecting the flue system and ensuring proper ventilation, our meticulous approach leaves no stone unturned. We also pay close attention to the fireplace components, including the firebox, damper, and gas lines, to guarantee their optimal functioning. With our thorough fireplace inspections, you can enjoy the cozy warmth of your fireplace with peace of mind, knowing that it has been professionally evaluated and deemed safe for use.",
    p3Title: "thorough fireplace inpsections",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  // {
  //   id: "repair",
  //   header: "Fireplace Repair",
  //   imgUrl:
  //     "https://res.cloudinary.com/sullivana10/image/upload/v1662129937/kelsey-roenau-gxtkdHecsdM-unsplash_hfilm2.jpg",
  //   p1Title: "this is placeholder text",
  //   p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
  //   p2Title: "this is placeholder text",
  //   p2: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iusto nihil sint expedita quasi sit velit consectetur sunt quia! Veritatis sequi at mollitia architecto provident veniam maiores obcaecati labore aliquid temporibus. Fugiat illum maxime adipisci esse tempore dicta cupiditate harum veritatis velit. Atque quos provident necessitatibus. Ad quidem odio minima illo reprehenderit nobis! Numquam alias dolor consequatur blanditiis mollitia maiores nihil. Aliquid ipsa quis quod quidem cumque, asperiores alias minus quam nobis? Iure quam repellendus eos porro nesciunt, unde ipsam a officiis quo?",
  //   p3Title: "this is placeholder text",
  //   p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  // },
  {
    id: "remodel",
    header: "Fireplace Remodel",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663909055/MFC_Services/Remodel_Stone-Veneer_lfeyqo.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "fireplace remodel",
    p2: "Transform your living space into a warm and inviting haven with our exceptional fireplace remodels. At Midwest Fireplace and Chimney Ltd., we specialize in breathing new life into outdated fireplaces, creating stunning focal points that enhance the ambiance of any home. Our team of skilled craftsmen and designers combine their expertise to deliver tailored fireplace renovations that align with your unique vision and style. Whether you desire a sleek modern design, a cozy rustic retreat, or a timeless traditional look, we have the knowledge and resources to bring your dream fireplace to fruition. From elegant mantels and surrounds to energy-efficient inserts and innovative hearth technologies, our remodels not only elevate the aesthetic appeal of your space but also provide enhanced functionality and comfort. Trust us to transform your fireplace into a captivating centerpiece that will be cherished for years to come.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "wood-inserts",
    header: "Wood Inserts",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663909964/MFC_Services/Wood_Insert_Install_e2rjjm.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "update your fireplace with a wood insert",
    p2: "Fireplace wood inserts are a popular choice for homeowners seeking to enhance the efficiency and beauty of their existing fireplaces. These inserts are designed to fit snugly into the opening of a traditional fireplace, providing a more efficient heating solution while preserving the charm of a crackling wood fire. With advanced combustion technology, fireplace wood inserts maximize heat output and minimize heat loss, ensuring a cozy and warm atmosphere in any room. They offer the convenience of easy operation and maintenance, with features such as adjustable airflow, thermostatic controls, and ash drawers for simple cleaning. Available in a variety of styles, from classic to contemporary, fireplace wood inserts not only improve heating efficiency but also add a touch of elegance to any home decor. Transform your old, inefficient fireplace into a highly functional and visually stunning centerpiece with a professionally installed wood insert.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "gas-inserts",
    header: "Gas Inserts",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663979186/MFC_Services/Gas_Inserts_g0hsok.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "update your fireplace with a gas insert",
    p2: "At Midwest Fireplace and Chimney Ltd., we specialize in the installation of fireplace gas inserts, providing a convenient and efficient heating solution for your home. Gas inserts offer the perfect blend of comfort, convenience, and style. Our experienced technicians are highly skilled in handling gas insert installations, ensuring a safe and seamless process from start to finish. With a wide range of gas insert options available, we can help you find the perfect fit for your fireplace, whether you prefer a contemporary design or a more traditional look. Gas inserts provide instant warmth at the touch of a button, eliminating the need for firewood and the hassle of cleaning up ashes. They also offer greater control over the temperature and flame intensity, allowing you to create the perfect ambiance for any occasion. With our expertise and commitment to customer satisfaction, we guarantee a beautiful and functional gas insert installation that will enhance the comfort and charm of your home. Discover the convenience and elegance of a gas insert and elevate your fireplace experience with our dedicated team.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "gas-log-install",
    header: "Gas Log Install",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663910319/MFC_Services/Gas_Logs_2_p1yvnx.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "burn clean with a gas log install",
    p2: "At Midwest Fireplace and Chimney Ltd., we take pride in providing professional gas log installation services that offer convenience, efficiency, and beauty to your home. Our experienced technicians are experts in fireplace enhancements, and they approach each gas log installation with meticulous attention to detail. Whether you're looking to convert your wood-burning fireplace to gas or simply upgrade your existing gas logs, we offer a wide selection of high-quality options to suit your preferences. Gas logs are crafted to mimic the natural look and feel of real wood, providing a realistic flame and cozy atmosphere without the hassle of traditional firewood. With our commitment to safety and expertise in gas connections, we ensure a seamless installation process that adheres to all necessary regulations. Discover the ease and elegance of a gas log installation with our dedicated team, and enjoy the convenience of instant warmth and ambiance in your home all year round.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "gas-fireplace-service",
    header: "Gas Fireplce Service",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663911861/MFC_Services/Gas_Fireplace_Service_stnnkl.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "gas fireplace service",
    p2: "We pride ourselves in offering exceptional gas fireplace services to ensure the warmth and comfort of our customers' homes. Our team of experienced technicians is skilled in providing comprehensive maintenance and repair solutions for gas fireplaces. From routine inspections and cleaning to diagnosing and fixing any issues, we go the extra mile to keep your gas fireplace operating safely and efficiently. With meticulous attention to detail, we conduct thorough checks on gas connections, valves, ignition systems, and ventilation, ensuring optimal performance and minimizing the risk of potential hazards. Trust us to deliver reliable and professional gas fireplace service that will keep your hearth glowing for years to come.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "new-construction",
    header: "New Construction",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663910619/MFC_Services/New_Construction_lio4dx.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "new fireplace construction",
    p2: "At Midwest Fireplace and Chimney Ltd., we specialize in crafting exquisite fireplaces that become the heart and soul of any home. Our team combines timeless craftsmanship with innovative designs to create bespoke fireplaces that cater to your unique taste and style. Whether you envision a modern, minimalist masterpiece or a classic, rustic retreat, our expertise ensures that every detail is meticulously tailored to surpass your expectations. From selecting the finest materials to incorporating cutting-edge technologies, we guarantee a seamless construction process and an end result that exudes both elegance and functionality.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "custom-doors",
    header: "Custom Doors",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663911008/MFC_Services/Custom_Doors_2_a9ykd0.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "custom fireplace doors",
    p2: "We take pride in offering exceptional custom fireplace doors that seamlessly blend functionality with elegance. Custom fireplace doors are crafted to fit your fireplace dimensions perfectly, ensuring a snug and secure fit. With a wide range of designs, finishes, and materials to choose from, you can truly personalize your fireplace and enhance its aesthetic appeal. Skilled artisans employ the highest quality materials to create exquisite fireplace doors that not only elevate the visual appeal of your hearth but also provide added safety and energy efficiency. Whether you desire a contemporary, traditional, or rustic look, custom fireplace doors are designed to complement any style of home decor, adding a touch of sophistication and charm to your living space.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "fireplace-reline",
    header: "Fireplace Reline",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663911327/MFC_Services/Fireplace_Reline_maorp8.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "fireplace reline",
    p2: "At Midwest Fireplace and Chimney Ltd., we specialize in providing top-notch fireplace reline services that enhance the functionality and safety of your fireplace. A properly lined fireplace is essential for optimal performance and to prevent the risk of hazardous gases and smoke leaks. Our team of experienced professionals is well-versed in the latest fireplace reline techniques and utilizes high-quality materials to ensure long-lasting results. Whether it's a traditional masonry fireplace or a modern insert, we carefully assess your unique needs and recommend the most suitable relining solution. With our meticulous attention to detail and commitment to customer satisfaction, you can trust us to deliver exceptional fireplace reline services that transform your fireplace into a cozy and secure focal point for your home.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
];

export const cItemData = [
  {
    id: "sweep-and-inspection",
    header: "Sweep & Inspection",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663913209/MFC_Services/Cleaning_Inspection_laibr3.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "cleaning and inspections are recommended annually",
    p2: "At Midwest Fireplace and Chimney Ltd., we understand the importance of chimney sweeps and inspections for maintaining the safety and functionality of your chimney system. Chimney sweeps play a vital role in removing built-up creosote, debris, and obstructions that can hinder proper airflow and pose potential fire hazards. Our skilled and certified chimney sweeps meticulously clean your chimney, ensuring its optimal performance and reducing the risk of chimney fires. Additionally, our comprehensive chimney inspections provide a thorough assessment of your chimney's condition, identifying any structural issues, leaks, or other potential problems that may require immediate attention. With our expert chimney sweeps and inspections, you can enjoy peace of mind, knowing that your chimney is in the best possible hands.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "chimney-rebuild",
    header: "Chimney Rebuild",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663903746/MFC_Services/Rebuild_2_sed3tl.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "full chimney rebuild",
    p2: "We specialize in full chimney rebuilds to ensure the safety and longevity of your chimney system. Our experienced team of fireplace and chimney experts understands the importance of maintaining a structurally sound and efficient chimney. Whether your chimney has suffered extensive damage, requires significant repairs, or needs a complete overhaul, we are here to deliver exceptional service. Using high-quality materials and advanced techniques, we meticulously reconstruct your chimney, paying attention to every detail. From the foundation to the crown, we leave no stone unturned in restoring your chimney to its former glory. With our comprehensive chimney rebuilds, you can enjoy a safe, functional, and aesthetically pleasing chimney that enhances the beauty and value of your home.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "tuck-pointing",
    header: "Tuck-pointing",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663903855/MFC_Services/Tuck-Pointing_g4ynz1.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "restore your chimney with tuck-pointing",
    p2: "At Midwest Fireplace and Chimney Ltd., we specialize in the art of tuckpointing, a meticulous process that restores the beauty and integrity of your chimney. Tuckpointing is a skillful technique that involves removing damaged or deteriorating mortar joints and replacing them with fresh, high-quality mortar. Our team of experienced professionals takes great pride in their craftsmanship, paying attention to every detail to ensure a seamless and visually stunning result. Whether your chimney is suffering from cracks, gaps, or crumbling mortar, our tuckpointing services will not only enhance its appearance but also strengthen its structure, providing long-lasting protection against moisture and weathering. With our dedication to quality and customer satisfaction, you can trust us to breathe new life into your chimney, making it a standout feature of your home once again.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "concrete-crowns",
    header: "Concrete Crowns",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663902748/MFC_Services/Chimney_Crown_2_omfmd8.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "concrete crowns",
    p2: "A chimney concrete crown plays a crucial role in protecting your chimney from the elements. It serves as a durable and reliable barrier that prevents water, debris, and pests from infiltrating your chimney system. Our skilled technicians are experienced in constructing and repairing concrete crowns with precision and expertise. Using high-quality materials and advanced techniques, we ensure that your concrete crown is built to last, providing long-term protection and structural integrity for your chimney.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  // {
  //   id: "chimney-caps",
  //   header: "Chimney Caps",
  //   imgUrl:
  //     "https://res.cloudinary.com/sullivana10/image/upload/v1662129937/kelsey-roenau-gxtkdHecsdM-unsplash_hfilm2.jpg",
  //   p1Title: "this is placeholder text",
  //   p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
  //   p2Title: "chimney caps",
  //   p2: "More information coming soon! Call today with any questions.",
  //   p3Title: "this is placeholder text",
  //   p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  // },
  {
    id: "chimney-liners",
    header: "Chimney Liners",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663902974/MFC_Services/Chimney_Liners_drkabo.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "chimney liners",
    p2: "We specialize in providing top-quality chimney liners that ensure the safety and efficiency of your fireplace. A chimney liner is an essential component of any chimney system as it protects the masonry from the corrosive effects of flue gases and prevents the risk of chimney fires. Our expert team understands the importance of a properly functioning chimney liner, which is why we offer a wide range of options to suit your specific needs.With our high-quality chimney liners, you can enjoy the warmth and ambiance of your fireplace with confidence, knowing that your chimney system is secure and performing optimally. Trust us to provide you with reliable and long-lasting chimney liner solutions that prioritize both safety and comfort.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "flashing",
    header: "flashing",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663903219/MFC_Services/flashing_ne8mzm.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "flashing",
    p2: "Chimney flashing is a critical component of chimney maintenance and protection. It involves the installation of a weatherproof barrier, typically made of metal, to prevent water from seeping into the vulnerable areas where the chimney meets the roof. Proper chimney flashing helps prevent water damage, leaks, and potential structural issues, ensuring the longevity and safety of your chimney and home. At Midwest Fireplace and Chimney Ltd., we understand the importance of proper chimney flashing to safeguard your home from water damage. Our skilled team specializes in expert flashing installations, repairs, and replacements, utilizing high-quality materials and advanced techniques. With our meticulous craftsmanship and attention to detail, we ensure that your chimney flashing not only functions flawlessly but also enhances the aesthetic appeal of your home.Keep your chimney secure and your home protected with our trusted flashing services.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "crown-coat",
    header: "Crown Coat",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663903597/MFC_Services/Crown_Coat_giqltr.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "crown coat",
    p2: "We offer professional chimney crown coats to ensure the longevity and functionality of your chimney system. A chimney crown coat is a protective layer that shields the chimney crown, which is the topmost part of your chimney, from the damaging effects of weather and environmental elements. Our skilled technicians apply high-quality chimney crown coatings, designed to resist water penetration, freeze-thaw cycles, and UV radiation. By providing a strong barrier against moisture and preventing cracks, our chimney crown coats safeguard your chimney from potential structural damage, reducing the risk of costly repairs in the future. With our reliable and durable chimney crown coats, you can enjoy peace of mind knowing that your chimney is well-protected and able to withstand the test of time.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "chase-covers",
    header: "Chase Covers",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663906239/MFC_Services/Chimney_Chase_Cover_After_uprfmt.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "chase covers",
    p2: "A chimney chase cover serves as a protective barrier, shielding your chimney chase from the elements and preventing water, debris, and animals from entering your chimney system. Our chimney chase covers are expertly crafted using durable materials such as stainless steel or copper, ensuring longevity and resistance to rust and corrosion. We understand the importance of a properly functioning chimney system, and our experienced team is dedicated to providing custom-fit chimney chase covers that seamlessly blend with the architectural aesthetics of your home. With our top-notch chase covers, you can enjoy a worry-free experience and maintain the integrity of your chimney for years to come. Trust us to safeguard your chimney chase and provide unmatched protection for your home.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "power-washing",
    header: "Power Washing",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1663907848/MFC_Services/BEFORE_gojjja.png",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "Power Washing",
    p2: "At Midwest Fireplace and Chimney Ltd., we understand the importance of maintaining not only the functionality but also the aesthetic appeal of your chimney. That's why we offer professional exterior chimney power washing services. Over time, chimneys can accumulate dirt, grime, and unsightly stains due to exposure to the elements. Our team of skilled technicians utilizes high-pressure power washing techniques to effectively remove years of built-up residue, restoring your chimney's exterior to its original splendor. With our attention to detail and commitment to quality, you can trust us to revitalize the appearance of your chimney, enhancing the overall curb appeal of your home. Say goodbye to the dingy and worn-out exterior, and let us bring back the shine to your chimney.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
  {
    id: "pcr",
    header: "P.C.R. Creosote Glaze Removal",
    imgUrl:
      "https://res.cloudinary.com/sullivana10/image/upload/v1664211560/pcr_vijtrp.jpg",
    p1Title: "this is placeholder text",
    p1: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur autem aliquid voluptatibus debitis. Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Rem voluptatem fugiat quis consequuntur, id nam. Praesentium dignissimos velit sit eligendi dicta, recusandae obcaecati numquam ullam nostrum esse harum culpa quas quasi repudiandae molestias nobis iure alias totam sapiente, ea eos laudantium ab delectus ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum esse harum culpa quas quasi repudiandae molestias maxime! Repellat quisquam ut quam aut optio doloribus nesciunt expedita magni. Corporis ullam nostrum esse harum culpa quas quasi repudiandae molestias ullam nostrum dignissimos quam aut optio doloribus velit sit ! Consequatur Repellat quisquam ut quam autem aliquid voluptatibus debitis",
    p2Title: "P.C.R. Stage 3 Creosote Glaze Removal",
    p2: "Creosote glaze buildup is a common problem in chimneys, and it can pose serious risks such as chimney fires. Our skilled technicians specifically target Stage 3 creosote glaze, which is the most stubborn and hazardous form. Through this process, we utilize specialized tools and equipment to effectively break down and remove the hardened glaze, ensuring the safe and efficient functioning of your chimney system. Our team is trained to handle this delicate procedure with precision, while prioritizing the safety of your home and family. With our P.C.R. Stage 3 Creosote Glaze Removal service, we guarantee a thorough and reliable solution to protect your chimney from potential hazards and improve its overall performance.",
    p3Title: "this is placeholder text",
    p3: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus natus labore consectetur esse autem maiores! Architecto officiis at sunt commodi delectus esse ducimus. Ipsum perferendis dolorem totam, reprehenderit officiis alias? Fuga aliquid dicta sunt nihil nulla ea in, laudantium ducimus natus repudiandae similique animi, dignissimos cum ex quia id voluptatibus vel, ipsam sed placeat dolore reprehenderit! Saepe sunt labore consectetur esse autem commodi delectus esse cum ex quia id reprehenderit officiis laudantium ducimus natus nisi ad vitae?",
  },
];
